import { useEffect, useState, useRef } from "react";
import Message from "./message";

export default function Chat({ socket, loaded, joinTriggered }) {
  const [message, setMessage] = useState("");
  const [messageHistory, setMessageHistory] = useState([]);
  const [isThinking, setIsThinking] = useState(true);
  const messagesChatRef = useRef(null);
  const writeMessageRef = useRef(null);
  const [processedJoin, setProcessedJoin] = useState(false);

  const addMessage = (messageId, newText, isUser, time) => {
    if (messageHistory.some(msg => msg.messageId === messageId)) {
      return;
    }

    const newMessage = {
      messageId: messageId,
      text: newText,
      timeUTC: time,
      isUser: isUser,
      isNew: window.fullyLoaded
    };

    setMessageHistory(prevMessages => {
      // First, filter out any existing message with the same messageId
      const filteredMessages = prevMessages.filter(msg => msg.messageId !== messageId);

      // Add the new message to the array
      const updatedMessages = [...filteredMessages, newMessage];

      // Sort the messages by timeUTC in ascending order
      updatedMessages.sort((a, b) => a.timeUTC - b.timeUTC);

      return updatedMessages;
    });
  };

  const handleText = (e) => {
    const inputMessage = e.target.value;
    setMessage(inputMessage);
  };

  const handleSubmit = () => {
    if (!message) {
      return;
    }
    socket.emit("data", message);
    setMessage("");
    addMessage(Math.floor(Date.now() / 1000).toString(), message, true, new Date().toISOString());
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
    if (writeMessageRef.current) {
      writeMessageRef.current.style.height = 'auto';
      writeMessageRef.current.style.height = `${writeMessageRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    if (writeMessageRef.current && loaded) {
      const timer = setTimeout(() => {
        // writeMessageRef.current.focus();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [writeMessageRef, loaded]);

  useEffect(() => {
    if (document.body.scrollWidth >= 648) {
      if (messagesChatRef.current) {
        messagesChatRef.current.scrollTop = messagesChatRef.current.scrollHeight + 60;
      }
    } else if (window.fullyLoaded) {
      window.scrollTo(0, document.body.scrollHeight - 100);
    }
  }, [messageHistory, isThinking]);

  useEffect(() => {
    socket.on("data", (data) => {
      setIsThinking(false);
      addMessage(data.id, data.message, data.is_user, new Date(data.created_at * 1000));
    });
    socket.on("thinking", () => {
      setIsThinking(true);
    });
    socket.on("error", (data) => {
      setIsThinking(false);
      addMessage(Math.floor(Date.now() / 1000).toString(), data.error, false, new Date().toISOString());
    });

    return () => {
      socket.off("data", () => {
      });
    };
    // eslint-disable-next-line
  }, [socket]);

  useEffect(() => {
    if (joinTriggered && socket && !processedJoin) {
      setProcessedJoin(true)

      const joinMessage = "Please add me to the waitlist."
      socket.emit("data", joinMessage);
      addMessage(Math.floor(Date.now() / 1000).toString(), joinMessage, true, new Date().toISOString());

      if (messagesChatRef.current) {
        window.scrollTo(0, messagesChatRef.current.offsetTop - 20);
      }
    }
    // eslint-disable-next-line
  }, [joinTriggered, processedJoin, socket, messagesChatRef]);

  return (
    <div className="container">
      <div className="row">
        <section className="chat">
          <div className="messages-chat" ref={messagesChatRef}>
            <div className="messages">
              {messageHistory.map((message, index) => (
                <Message key={index} message={message} container={messagesChatRef} />
              ))}

              {isThinking &&
                <div className="message">
                  <div className="photo" style={{ backgroundImage: 'url(images/logo.svg)' }}>
                  </div>
                  <p className="text">
                    <img className="messageThinking" src="images/thinking.svg" alt="Thinking..." />
                  </p>
                </div>
              }
            </div>
          </div>
          <div className="footer-chat">
            <textarea className="write-message"
              ref={writeMessageRef}
              placeholder="Type your message here"
              value={message}
              onChange={handleText}
              onKeyUp={handleKeyPress}>
            </textarea>
            <button className="icon send fa fa-paper-plane-o clickable" aria-hidden="true" onClick={handleSubmit}></button>
          </div>
        </section>
      </div>
    </div>
  );
}
