import "./App.css";
import Chat from "./components/chat";
import { io } from "socket.io-client";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import Typed from 'typed.js';

function App() {
  const [userId, setUserId] = useState(undefined);
  const [connected, setConnected] = useState(false);
  const [firstVisit, setFirstVisit] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [socketInstance, setSocketInstance] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [triggerJoin, setTriggerJoin] = useState(false);

  const handleJoinClick = () => {
    setTriggerJoin(true);
  };


  useEffect(() => {
    if (!connected || !userId || !socketInstance) {
      return;
    }

    const queryParams = new URLSearchParams(window.location.search);
    const refCode = queryParams.get('r') || '';
    socketInstance.emit("handshake", JSON.stringify(
      {
        "userId": userId,
        "refCode": refCode,
        "userTime": new Date().toLocaleString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }),
      }
    ));
  }, [connected, userId, socketInstance]);

  useEffect(() => {
    const uId = localStorage.getItem('userId');
    var typed;
    if (uId) {
      setUserId(uId);
      setFirstVisit(false);
      setLoaded(true);
    } else {
      const uId = uuidv4().toString()
      setUserId(uId)
      localStorage.setItem('userId', uId);

      typed = new Typed('#header-anim', {
        strings: ['is key?', 'is table stakes?', 'could be better?         <br /><br />      <span className="header-chat">We should chat...</span>'],
        typeSpeed: 40,
        startDelay: 1750,
        smartBackspace: false,
        backDelay: 1250,
        backSpeed: 55,
        autoInsertCss: true,
        fadeOut: false,
        onComplete: (self) => {
          self.cursor.remove();
          setLoaded(true);
        },
      });
    }

    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const socket = io(backendUrl, {
      transports: ["websocket"],
      cors: {
        origin: "http://localhost:3000/",
      },
    });

    setSocketInstance(socket);

    socket.on("connect", (data) => {
      setConnected(true);
    });

    setLoading(false);

    socket.on("disconnect", (data) => {
      setConnected(false);
    });

    return function cleanup() {
      socket.disconnect();
      if (typed) {
        typed.destroy();
      }
    };
  }, []);

  return (
    <>
      <section className="hero">
        <div className="container">
          <div className={`hero-inner ${firstVisit ? 'first-visit' : ''}`}>
            <div className="hero-copy">
              <h1 className="hero-title mt-0">Run a business where turning website visitors into qualified
                leads{" "}<br className="mobile-only" />
                <span id="header-anim">
                  {!firstVisit && (
                    <>
                      could be better?
                      <br /><br />
                      <span className="header-chat">We should chat...</span>
                    </>
                  )}
                </span>
              </h1>
            </div>
            <div className="hero-media">
              <div className="header-illustration">
                <img className="header-illustration-image asset-light" src="images/header-illustration-light.svg"
                  alt="Header illustration" />
              </div>
              <div className="hero-media-illustration">
                <img className="hero-media-illustration-image asset-light" src="images/hero-media-illustration-light.svg"
                  alt="Hero media illustration" />
              </div>
              <div className="hero-media-container">
                <div className="hero-media-chat">
                  <div className="App">
                    {!loading && <Chat socket={socketInstance} loaded={loaded} joinTriggered={triggerJoin} />}
                  </div >
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="features section">
        <div className="container">
          <div className="features-wrap">
            <div className="feature is-revealing">
              <div className="feature-inner">
                <div className="feature-icon">
                  <img className="asset-light" src="images/engage.png" alt="Engage" />
                </div>
                <div className="feature-content">
                  <h3 className="feature-title mt-0">Engage</h3>
                  <p className="text-sm mb-0">The visitors of your website need to know quickly whether you can provide are
                    what they need. We're presenting a new way to get their attention and engage with them.</p>
                </div>
              </div>
            </div>
            <div className="feature is-revealing">
              <div className="feature-inner">
                <div className="feature-icon">
                  <img className="asset-light" src="images/build-trust.png" alt="Build Trust" />
                </div>
                <div className="feature-content">
                  <h3 className="feature-title mt-0">Build Trust</h3>
                  <p className="text-sm mb-0">Have a conversation with your visitors, ask them about their needs and present
                    the options that would be helpful to them. 24/7. Automatically.</p>
                </div>
              </div>
            </div>
            <div className="feature is-revealing">
              <div className="feature-inner">
                <div className="feature-icon">
                  <img className="asset-light" src="images/convert.png" alt="Convert" />
                </div>
                <div className="feature-content">
                  <h3 className="feature-title mt-0">Convert</h3>
                  <p className="text-sm mb-0">Let the AI guide your visitors into becoming more interested in hearing from
                    you. Collect their phone number or email address. Even let them schedule a call or visit.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cta section">
        <div className="container-sm">
          <div className="cta-inner section-inner">
            <div className="cta-header text-center">
              <h2 className="section-title mt-0">Early Access Program</h2>
              <p className="section-paragraph">
                ChatWithLeads isn't quite ready yet. We are collecting information from people that are interested in trying it out first.
              </p>
              <div className="cta-cta">
                <button className="button button-primary" onClick={handleJoinClick}>Join the Waitlist</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default App;
