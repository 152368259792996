import { useCallback, useEffect, useState, useRef } from "react";
import { formatDistanceToNow } from 'date-fns';
import ReactMarkdown from 'react-markdown';
import Typed from 'typed.js';

const formatDistanceToNowShort = (date) => {
    const distance = formatDistanceToNow(date, { addSuffix: true, includeSeconds: true });
    return distance
        .replace(/about /g, '')
        .replace(/half a minute/g, '<1m')
        .replace(/less than a minute/g, '<1m')
        .replace(/ minutes/g, 'm')
        .replace(/ minute/g, 'm')
        .replace(/ hours/g, 'h')
        .replace(/ hour/g, 'h')
        .replace(/ days/g, 'd')
        .replace(/ day/g, 'd')
        .replace(/ months/g, 'mo')
        .replace(/ month/g, 'mo')
        .replace(/ years/g, 'y')
        .replace(/ year/g, 'y')
        .replace(/ seconds/g, 's')
        .replace(/ second/g, 's');
};

const useTimeAgo = (dateUTC) => {
    const [timeAgo, setTimeAgo] = useState('');

    useEffect(() => {
        // Function to update time ago
        const updateTimeAgo = () => {
            setTimeAgo(formatDistanceToNowShort(new Date(dateUTC)));
        };

        updateTimeAgo();
        const interval = setInterval(updateTimeAgo, 10000);

        // Clear interval on component unmount
        return () => clearInterval(interval);
    }, [dateUTC]);

    return timeAgo;
};

export default function Message({ message, container }) {
    const timeAgo = useTimeAgo(message.timeUTC);
    const typedContainerRef = useRef(null);
    const scrollInterval = useRef(null);

    const scrollToEnd = useCallback(() => {
        if (document.body.scrollWidth >= 648) {
            if (container.current) {
                container.current.scrollTop = container.current.scrollHeight + 60;
            }
        } else {
            window.scrollTo(0, document.body.scrollHeight - 100);
        }
    }, [container]);

    useEffect(() => {
        if (message.isNew && !message.isUser && typedContainerRef?.current) {
            const options = {
                strings: [message.text],
                typeSpeed: 25,
                autoInsertCss: true,
                fadeOut: false,
                onBegin: () => {
                    scrollInterval.current = setInterval(scrollToEnd, 1000);
                },
                onComplete: (self) => {
                    self.cursor.remove();
                    clearInterval(scrollInterval.current);
                },
            };

            const typed = new Typed(typedContainerRef.current, options);
            return () => {
                typed.destroy();
                if (scrollInterval.current) {
                    clearInterval(scrollInterval.current); // Ensure interval is cleared on cleanup
                }
            };
        }
    }, [message, scrollToEnd]);

    if (message.isUser) {
        // Layout for user messages
        return (
            <>
                <div className="message text-only">
                    <div className="response">
                        <p className="text">{message.text}</p>
                    </div>
                </div>
                <p className="response-time time">{timeAgo}</p>
            </>
        );
    } else {
        // Layout for non-user messages
        return (
            <>
                <div className="message message-assistant">
                    <div className="photo" style={{ backgroundImage: 'url(images/logo.svg)' }}>
                    </div>
                    <p className="text">
                        {message.isNew ? (
                            <span ref={typedContainerRef}></span>
                        ) : (
                            <ReactMarkdown>{message.text}</ReactMarkdown>
                        )}
                    </p>
                </div>
                <p className="time">{timeAgo}</p>
            </>
        );
    }
};
